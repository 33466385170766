// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Toast.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Toast.tsx");
  import.meta.hot.lastModified = "1709575828557.8997";
}
// REMIX HMR END

import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Toaster, toast } from "sonner";
import { useTypedLoaderData } from "remix-typedjson";
import { useEffect } from "react";
import { Paragraph } from "./Paragraph";
const defaultToastDuration = 5000;
const permanentToastDuration = 60 * 60 * 24 * 1000;
export function Toast() {
  _s();
  const {
    toastMessage
  } = useTypedLoaderData();
  useEffect(() => {
    if (!toastMessage) {
      return;
    }
    const {
      message,
      type,
      options
    } = toastMessage;
    toast.custom(t => <ToastUI variant={type} message={message} t={t} />, {
      duration: options.ephemeral ? defaultToastDuration : permanentToastDuration
    });
  }, [toastMessage]);
  return <Toaster />;
}
_s(Toast, "PWsaebp1YTCaLDEY/Auqp8vZPz8=", false, function () {
  return [useTypedLoaderData];
});
_c = Toast;
export function ToastUI({
  variant,
  message,
  t,
  toastWidth = 356 // Default width, matches what sonner provides by default
}) {
  return <div className={`self-end rounded-md border border-grid-bright bg-background-dimmed`} style={{
    width: toastWidth
  }}>
      <div className="flex w-full items-start gap-2 rounded-lg p-3">
        {variant === "success" ? <CheckCircleIcon className="mt-1 h-6 min-h-[1.5rem] w-6 min-w-[1.5rem] text-green-600" /> : <ExclamationCircleIcon className="mt-1 h-6 w-6 min-w-[1.5rem] text-rose-600" />}
        <Paragraph className="py-1 text-text-dimmed">{message}</Paragraph>
        <button className="hover:bg-midnight-800 ms-auto rounded p-2 text-text-dimmed transition hover:text-text-bright" onClick={() => toast.dismiss(t)}>
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
    </div>;
}
_c2 = ToastUI;
var _c, _c2;
$RefreshReg$(_c, "Toast");
$RefreshReg$(_c2, "ToastUI");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;